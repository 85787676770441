// Migrated 
// TODO: check if popper works correctly
<template lang="pug">
component.v-popper__wrapper.dropdown__content(
  :is="as"
  v-if="$parent.isOpened()"
  :id="`${$parent.id}-menu`"
  role="menu"
  :aria-labelledby="`${$parent.id}-toggle`"
  :class="{'w-100': fullWidth}"
  @click="onClick"
)
  .v-popper__inner(:class="[scrollShadow ? 'scroll-shadows--sm dropdown-inner-shadow' : '', { 'w-100': fullWidth }]")
    slot
</template>

<script>
// https://gist.github.com/plmrlnsnts/4587f5856608140609f27a29d77d052f
import { createPopper } from '@popperjs/core'

export default defineNuxtComponent({
  props: {
    as: {
      type: String,
      default: 'div'
    },

    placement: {
      type: String,
      default: 'bottom-start'
    },

    offset: {
      type: Array,
      default () {
        return [0, 0]
      }
    },

    fullWidth: {
      type: Boolean,
      default: false
    },

    closeOnClick: {
      type: Boolean,
      default: false
    },

    scrollShadow: {
      type: Boolean,
      default: false
    }
  },

  data: vm => ({
    focusedChild: -1,
    popperOptions: {
      placement: vm.$props.placement,
      modifiers: {
        name: 'offset',
        options: {
          offset: vm.$props.offset,
        }
      }
    }
  }),

  watch: {
    focusedChild (value) {
      if (value < 0) { return }
      this.focusableChildren()[value].focus()
    },

    '$parent.status' (value) {
      if (value === 'closed') { return this.popper.destroy() }

      this.$nextTick(() => (this.popper = createPopper(
        this.$parent.$el, this.$el, this.popperOptions
      )))

      this.focusedChild = -1
    },
  },

  mounted () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const onKeydown = (event) => {
      if (this.$parent.isClosed()) { return }

      switch (event.key) {
        case 'Tab':
          event.preventDefault()
          event.shiftKey ? this.previous() : this.next()
          break
        case 'Enter':
          event.preventDefault()
          if (this.focusedChild === -1) { return }
          this.focusableChildren()[this.focusedChild].click()
          break
        case 'Escape':
          event.preventDefault()
          this.$parent.close()
          break
        case 'ArrowUp':
          event.preventDefault()
          this.previous()
          break
        case 'ArrowDown':
          event.preventDefault()
          this.next()
          break
        case 'Home':
          event.preventDefault()
          this.focusedChild = 0
          break
        case 'End':
          event.preventDefault()
          this.focusedChild = this.focusableChildren().length - 1
          break
      }
    }

    document.addEventListener('keydown', this.onKeydown)
  },

  beforeUnmount () {
    document.removeEventListener('keydown', this.onKeydown)
  },

  methods: {
    previous () {
      this.focusedChild = this.focusedChild > 0
        ? this.focusedChild - 1
        : this.focusableChildren().length - 1
    },

    next () {
      this.focusedChild = this.focusedChild < (this.focusableChildren().length - 1)
        ? this.focusedChild + 1
        : 0
    },

    focusableChildren () {
      return this.$el.querySelectorAll([
        'a, button, input, textarea, select',
        'details, [tabindex]:not([tabindex="-1"])'
      ].join(','))
    },

    onClick () {
      if (this.closeOnClick) {
        this.$parent.close()
      }
    }
  }
})
</script>

<style lang="scss">
.v-popper__wrapper {
  position: fixed;
  z-index: 500;
  left: auto;
  top: auto;
  margin-top: 5px;
  filter: drop-shadow(0px 2px 3px rgba(0,0,0,.1));

  & > div {
    border-radius: .5rem;
  }
}
.dropdown-inner-shadow {
  max-height: 400px !important;
  background-color: white;
}
</style>
